import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import Resident from "../models/Resident";
import FaceRecognition from "../models/FaceRecognition";
import NFSignature from "../models/NFSignature";
import env from "../util/env";
import * as Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import {BsCheck, BsCheckLg, BsFileEarmarkExcel} from "react-icons/bs";
import Style from "../Style";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import FaceMatchEnrollment from "../models/FaceMatchEnrollment";
import Enrollment from "../models/Enrollment";
import FaceMatch from "../models/FaceMatch";
import {AiOutlineClose} from "react-icons/ai";
import {CgClose} from "react-icons/cg";
import AnalyticUsage from "../models/AnalyticUsage";
import moment from "moment";
import FaceDemography from "../models/FaceDemography";

export default function FaceDemographyPage(props) {
    const {base64, ktpBase64} = props.location.state;
    const nfSignatureModel = new NFSignature();
    const faceMatchModel = new FaceMatch();
    const faceDemographyModel = new FaceDemography();
    const [nfHeaders, setNfHeaders] = useState({})
    const analyticUsageModel = new AnalyticUsage();
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    const [faceDemographyResult, setFaceDemographyResult] = useState({});

    const getNFSignature = async () => {
        try {
            setLoading(true)

            const result = await nfSignatureModel.get();

            setNfHeaders({
                'Content-Type': 'application/json',
                Authorization: `NODEFLUX-HMAC-SHA256 Credential=${env.NF_ACCESS_KEY}/${result.headers["x-nodeflux-timestamp"].substring(0, 8)}/nodeflux.api.v1beta1.ImageAnalytic/StreamImageAnalytic, SignedHeaders=x-nodeflux-timestamp, Signature=${result.token}`,
                'x-nodeflux-timestamp': result.headers["x-nodeflux-timestamp"]
            })

            setLoading(false)
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    }

    const faceDemography = async () => {
        setLoading(true)

        try {
            const result = await faceDemographyModel.get(`data:image/jpeg;base64,${base64}`, nfHeaders)

            console.log('result', result)

            if (result.job && result.job.result && result.job.result.status === 'on going') {
                return faceDemography()
            } else if(result.job.result.result[0].error === 'Failed to detect face') {
                Swal.fire(
                    "Wajah Tidak Dapat Dikenali!",
                    ``,
                    'error'
                ).then(history.goBack)
            } else {
                await analyticUsageModel.create("VISIONAIRE-CLOUD-SNAP-FD Face Demography")
                setFaceDemographyResult(result.job.result.result[0].face_demography[0])
                console.log('result', result)
            }



            // if (result.result[0].face_match.match) {
            //     Swal.fire(
            //         "Verifikasi Berhasil!",
            //         `Foto selfie dengan EKTP cocok dengan keyakinan ${result.result[0].face_match.similarity === 1 ? '99%' : Math.floor(result.result[0].face_match.similarity * 100) + '%'}`,
            //         'success'
            //     )
            // } else {
            //     Swal.fire(
            //         "Verifikasi Gagal!",
            //         `Foto selfie dengan EKTP tidak cocok`,
            //         'error'
            //     )
            // }
            //
            // setFaceMatchResult(result)
        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    }

    useEffect(() => {
        getNFSignature()
    }, [])

    useEffect(() => {
        if (Object.keys(nfHeaders).length > 0) {
            faceDemography()
        }
    }, [nfHeaders])

    const getPercentage = (number) => {
        const percentage = Math.round(number * 100 * 10) / 10

        return percentage === 100 ? '99.9%' : percentage + "%"
    }

    return (
        <Container fluid>
            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Face Demography
                </Col>
            </Row>

            <Row>
                <Col style={{fontWeight: '600', marginTop: 15}}>
                    Foto Muka
                </Col>
            </Row>

            <Row>
                <Col style={{boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px', margin: 15, borderRadius: 5}}>
                    <img src={`data:image/jpeg;base64,${base64}`}
                         style={{width: '100%', height: 200, objectFit: 'contain'}}/>
                </Col>
            </Row>

            {
                faceDemographyResult.gender ?
                    <Row>
                        <Col style={{fontWeight: '600', marginTop: 15}}>
                            Hasil
                        </Col>
                    </Row> : null
            }

            {isLoading ?
                <Col style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 15
                }}>
                    <Spinner animation={'border'} variant={'secondary'}/>
                    <div style={{color: 'grey', marginTop: 10}}>
                        Memuat Hasil
                    </div>
                </Col> :
                faceDemographyResult.gender ?
                <Container style={{marginTop: 20, marginBottom: 20}}>
                    <Row>
                        <Col style={Style.card}>
                            <div style={{fontWeight: '700', fontSize: 13}}>
                                Gender
                            </div>

                            <div>
                                {faceDemographyResult.gender?.prediction} - {getPercentage(faceDemographyResult.gender?.confidence)}
                            </div>

                            <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                                Umur
                            </div>

                            <div>
                                {faceDemographyResult.age?.prediction} - {getPercentage(faceDemographyResult.age?.confidence)}
                            </div>

                            <Button
                                onClick={history.goBack}
                                style={{...Style.button, marginTop: 25}}
                                variant="primary">OK</Button>
                        </Col>
                    </Row>


                </Container> : null
            }

        </Container>
    )
}
