import logo from './logo.svg';
import './App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import 'bootstrap/dist/css/bootstrap.min.css';
import Palette from "./Palette";
import {Button} from "react-bootstrap";
import Style from "./Style";
import {BiChevronRight} from "react-icons/bi"
import React, {useEffect, useState} from "react";
import {BrowserRouter, Switch, Route, useHistory, Redirect} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import SearchByNIKPage from "./pages/SearchByNIKPage";
import PersonDetailPage from "./pages/PersonDetailPage";
import CameraPage from "./pages/CameraPage";
import FaceRecognitionPage from "./pages/FaceRecognitionPage";
import NIKValidationPage from "./pages/NIKValidationPage";
import EnrollmentPage from "./pages/EnrollmentPage";
import OCRResultPage from "./pages/OCRResultPage";
import FaceMatchPage from "./pages/FaceMatchPage";
import SettingsPage from "./pages/SettingsPage";
import KTPImageGuidancePage from "./pages/KTPImageGuidancePage";
import env from "./util/env"
import FaceDemographyPage from "./pages/FaceDemographyPage";

function App() {
    const history = useHistory();

    useEffect(() => {
        if(!localStorage.getItem('setting')) {
            localStorage.setItem('setting', JSON.stringify(env.DEFAULT_SETTING))
        }

        // if(!localStorage.getItem('token')) {
        //     history.push('/')
        // }
    }, [])



    return (
        <BrowserRouter basename="/">
            {!localStorage.getItem('token') ? <Redirect push to="/" /> : null}

            <Switch>
                <Route exact path="/" component={LoginPage}/>
                <Route exact path="/home" component={HomePage}/>
                <Route exact path="/search-nik" component={SearchByNIKPage}/>
                <Route exact path="/person-detail" component={PersonDetailPage}/>
                <Route exact path="/camera" component={CameraPage}/>
                <Route exact path="/face-recognition" component={FaceRecognitionPage}/>
                <Route exact path="/nik-validation" component={NIKValidationPage}/>
                <Route exact path="/enrollment" component={EnrollmentPage}/>
                <Route exact path="/ocr-result" component={OCRResultPage}/>
                <Route exact path="/face-match" component={FaceMatchPage}/>
                <Route exact path="/setting" component={SettingsPage}/>
                <Route exact path="/ktp-image-guidance" component={KTPImageGuidancePage}/>
                <Route exact path="/face-demography" component={FaceDemographyPage}/>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
