import ApiRequest from "../util/ApiRequest";
import apiConfig from "../util/apiConfig";
import env from "../util/env";

export default class NFSignature {
    get = async () => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/nf-cloud/signatures`, "POST", {
            access_key: env.NF_ACCESS_KEY,
            secret_key: env.NF_SECRET_KEY
        });
    }
}
