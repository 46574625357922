import Palette from "./Palette";

export default {
    header: {
        fontWeight: '600',
        boxShadow: 'rgb(136, 136, 136) 0px -2px 3px 1px',
        padding: 15,
        backgroundColor: 'white',
        fontSize: 16,
    },
    footer: {
        fontWeight: '600',
        boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 1px 1px',
        padding: 15,
        backgroundColor: 'white',
        fontSize: 16,
        position: 'fixed',
        bottom: 0,
        left: 0
    },
    button: {
        width: '100%',
        fontWeight: '600',
        fontSize: '.9em'
    },
    card: {
        boxShadow: 'rgb(136, 136, 136, .2) 0px 0px 3px 1px',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: 'white',
        borderRadius: 5,
    },
    menuCard: {
        fontWeight: '600',
        boxShadow: 'rgb(136, 136, 136, .2) 0px 0px 3px 1px',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: 'white',
        color: Palette.PRIMARY,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center'
    },
    formLabel: {
        color: Palette.PRIMARY,
        fontSize: '.9em'
    },
}
