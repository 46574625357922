module.exports = {
    NF_ACCESS_KEY: "H1DCER8AZW7O4SDODOPJYL639",
    NF_SECRET_KEY: "Q-l0rs34L-xIQ2srm7TFgvcNXDHPkvsWSxkEe6tcZmcJ7-ox-CFWL0BVxsnAEoQt",
    DEFAULT_SETTING: {
        enrollment: {
            liveness: false,
            ocr: false
        },
        faceRecognition: {
            liveness: false,
            candidateCount: 1,
            treshold: .8
        },
        verificationWithNIK: {
            liveness: false,
            ocr: false
        },
        verificationWithKTP: {
            liveness: false
        },
        liveness: {
            treshold: .6
        },
        searchByNIK: {
            ocr: false
        }
    }
};
