import '../App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from "react-bootstrap";
import Style from "../Style";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom"
import User from "../models/User"
import * as Swal from "sweetalert2";

export default function LoginPage() {
    const userModel = new User();
    const history = useHistory()
    const [loginCreds, setLoginCreds] = useState({});

    useEffect(() => {
        tokenLogin()
    }, [])

    const tokenLogin = async () => {
        if(localStorage.token) {
            try {
                const result = await userModel.tokenLogin()

                localStorage.token = result.token
                history.replace('/home')
            } catch (e) {
                console.log(e)
            }
        }
    }

    const attemptLogin = async () => {
        if(!loginCreds.email || !loginCreds.password) {
            Swal.fire(
                "Harap Mengisi Email dan Password!",
                ``,
                'error'
            )
        } else {
            try {
                const result = await userModel.login(loginCreds)

                localStorage.token = result.token
                history.replace('/home')
                console.log(result)
            } catch (e) {
                if(e.code === 'CREDENTIAL_NOT_FOUND') {
                    Swal.fire(
                        "Kombinasi Email dan Password Salah!",
                        ``,
                        'error'
                    )
                }
                console.log(e)
            }
        }
    }

    return (
        <Container fluid style={{
            backgroundColor: '#303030',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <div style={{flex: 1, marginTop: 15}}/>
            <Container style={{backgroundColor: 'white', borderRadius: 15}}>
                <Row>
                    <Col style={{padding: 30}}>
                        <div style={{textAlign: 'center', marginBottom: 10}}>
                            <img
                                style={{width: 140}}
                                src={'/nf-logo.png'}/>
                        </div>

                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>Email</Form.Label>
                                <Form.Control placeholder={'Email'}
                                              onKeyPress={(e) => {
                                                  if(e.key === 'Enter') {
                                                      attemptLogin()
                                                  }
                                              }}
                                              onChange={(e) => setLoginCreds({...loginCreds, email: e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.Controldivarea1">
                                <Form.Label style={Style.formLabel}>Password</Form.Label>
                                <Form.Control placeholder={'Password'} type={'password'}
                                              onKeyPress={(e) => {
                                                  if(e.key === 'Enter') {
                                                      attemptLogin()
                                                  }
                                              }}
                                              onChange={(e) => setLoginCreds({...loginCreds, password: e.target.value})}/>
                            </Form.Group>
                        </Form>

                        <Button
                            style={{...Style.button, marginTop: 15}}
                            onClick={attemptLogin}
                            variant="primary">MASUK</Button>

                        {/*<Button*/}
                        {/*    style={{...Style.button, marginTop: 10}}*/}
                        {/*    variant="outline-primary">MASUK DENGAN FACE SCAN</Button>*/}
                    </Col>
                </Row>
            </Container>

            <div style={{
                flex: 1,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                color: 'white',
                marginBottom: 15
            }}>
                <div style={{fontFamily: 'Montserrat'}}>
                    developed by
                </div>

                <div style={{fontFamily: 'Montserrat', fontWeight: '500'}}>
                    KOMUNE STUDIO
                </div>
            </div>
        </Container>
    );
}
