export default class ApiRequest {

    static set = async (endpoint, method, body, headers) => {
        headers = headers ? headers :
            {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                "Access-Control-Allow-Origin": "*",
                Authorization : localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : null
            };

        let response = await fetch(endpoint, {
            method: method,
            headers: new Headers({...headers}),
            body: JSON.stringify(body)
        });



        if (response.ok) {
            return response.json()
        } else {
            let error = await response.json()

            console.log('error : ', error)

            // console.log(error)

            throw error

        }
    }
}
