import env from "../util/env";
import apiConfig from "../util/apiConfig";
import ApiRequest from "../util/ApiRequest";

export default class FaceRecognition {
    get = async (faceId, base64, headers) => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/nf-cloud/face-match-enrollment`, "POST", {
            images: [base64],
            additional_params: {
                face_id: faceId,
            }
        }, headers);
    }
}
