import env from "../util/env";
import apiConfig from "../util/apiConfig";
import ApiRequest from "../util/ApiRequest";

export default class FaceDemography {
    get = async (base64, headers) => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/nf-cloud/face-demographic`, "POST", {
            images: [base64],
        }, headers);
    }
}
