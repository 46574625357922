import '../App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useHistory} from "react-router-dom";
import Style from "../Style";
import React, {useEffect, useState} from "react";
import {BiChevronRight} from "react-icons/bi";
import {RiSettings3Line} from "react-icons/ri";
import {MdLogout} from "react-icons/md";


export default function HomePage() {
    const history = useHistory();

    return (
        <Container fluid style={{
            minHeight: '100vh',
            backgroundColor: '#f2f2f2',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Row>
                <Col style={{
                    ...Style.header,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <div style={{flex: 1}}>
                        Nodeflux Demo
                    </div>

                    <RiSettings3Line size={20} onClick={() => history.push('setting')}/>

                    <MdLogout size={20} color={'red'} style={{marginLeft: 10}} onClick={() => {
                        localStorage.removeItem('token')
                        history.push('/')
                    }}/>
                </Col>
            </Row>

            <Container style={{marginTop: 20}}>
                <Row onClick={() => history.push('/camera', {type: '1:N'})}>
                    <Col style={Style.menuCard}>
                        <div style={{flex: 1}}>
                            Validasi 1:N
                        </div>

                        <BiChevronRight color={'grey'} size={20}/>
                    </Col>
                </Row>

                {/*<Row style={{marginTop: 15}} onClick={() => history.push('/camera', {type: '1:1'})}>*/}
                {/*    <Col style={Style.menuCard}>*/}
                {/*        <div style={{flex: 1}}>*/}
                {/*            Validasi 1:1*/}
                {/*        </div>*/}

                {/*        <BiChevronRight color={'grey'} size={20}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row style={{marginTop: 15}} onClick={() => history.push('/search-nik')}>*/}
                {/*    <Col style={Style.menuCard}>*/}
                {/*        <div style={{flex: 1}}>*/}
                {/*            Pencarian Data NIK*/}
                {/*        </div>*/}

                {/*        <BiChevronRight color={'grey'} size={20}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row style={{marginTop: 15}} onClick={() => history.push('/camera', {type: 'enrollment'})}>
                    <Col style={Style.menuCard}>
                        <div style={{flex: 1}}>
                            Pendaftaran Wajah
                        </div>

                        <BiChevronRight color={'grey'} size={20}/>
                    </Col>
                </Row>

                {/*<Row style={{marginTop: 15}} onClick={() => history.push('/ktp-image-guidance', {type: 'ocr'})}>*/}
                {/*    <Col style={Style.menuCard}>*/}
                {/*        <div style={{flex: 1}}>*/}
                {/*            OCR KTP*/}
                {/*        </div>*/}

                {/*        <BiChevronRight color={'grey'} size={20}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row style={{marginTop: 15}} onClick={() => history.push('/ktp-image-guidance', {type: 'face-match'})}>*/}
                {/*    <Col style={Style.menuCard}>*/}
                {/*        <div style={{flex: 1}}>*/}
                {/*            Verifikasi Muka Dengan EKTP*/}
                {/*        </div>*/}

                {/*        <BiChevronRight color={'grey'} size={20}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row style={{marginTop: 15}} onClick={() => history.push('/camera', {type: 'liveness'})}>*/}
                {/*    <Col style={Style.menuCard}>*/}
                {/*        <div style={{flex: 1}}>*/}
                {/*            Face Liveness*/}
                {/*        </div>*/}

                {/*        <BiChevronRight color={'grey'} size={20}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row style={{marginTop: 15}} onClick={() => history.push('/camera', {type: 'face-demography'})}>*/}
                {/*    <Col style={Style.menuCard}>*/}
                {/*        <div style={{flex: 1}}>*/}
                {/*            Face Demography*/}
                {/*        </div>*/}

                {/*        <BiChevronRight color={'grey'} size={20}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Container>

            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: 15
            }}>
                <div style={{fontFamily: 'Montserrat'}}>
                    developed by
                </div>

                <div style={{fontFamily: 'Montserrat', fontWeight: '600'}}>
                    KOMUNE STUDIO
                </div>
            </div>
        </Container>
    )
}
