import '../App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import Style from "../Style";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import * as Swal from "sweetalert2";
import Enrollment from "../models/Enrollment";
import {MdOutlineArrowBack} from "react-icons/md";
import env from "../util/env";
import OCR from "../models/OCR";
import AnalyticUsage from "../models/AnalyticUsage";
import NFSignature from "../models/NFSignature";

export default function SearchByNIKPage(props) {
    const setting = JSON.parse(localStorage.getItem('setting'));
    const [faceId, setFaceId] = useState('');
    const enrollmentModel = new Enrollment();
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [isOCRLoading, setOCRLoading] = useState(false);
    const [ktpBase64, setKtpBase64] = useState(null)
    const [nfHeaders, setNfHeaders] = useState({})
    const ocrModel = new OCR()
    const analyticUsageModel = new AnalyticUsage();
    const nfSignatureModel = new NFSignature();


    const getNFSignature = async () => {
        try {
            setOCRLoading(true)

            const result = await nfSignatureModel.get();

            setNfHeaders({
                'Content-Type': 'application/json',
                Authorization: `NODEFLUX-HMAC-SHA256 Credential=${env.NF_ACCESS_KEY}/${result.headers["x-nodeflux-timestamp"].substring(0, 8)}/nodeflux.api.v1beta1.ImageAnalytic/StreamImageAnalytic, SignedHeaders=x-nodeflux-timestamp, Signature=${result.token}`,
                'x-nodeflux-timestamp': result.headers["x-nodeflux-timestamp"]
            })

        } catch (e) {
            setOCRLoading(false)

            console.log(e)
        }
    }

    useEffect(() => {
        if(props.location.state) {
            setKtpBase64(props.location.state.base64)
        }
        console.log('props', props)
    }, [])

    useEffect(() => {
        if(ktpBase64) {
            getNFSignature()
        }
    }, [ktpBase64])

    const getOCR = async () => {
        try {
            const result = await ocrModel.get(`data:image/jpeg;base64,${ktpBase64}`, nfHeaders)

            if (result.job && result.job.result && result.job.result.status === 'on going') {
                return getOCR()
            }

            if (result.job && result.job.result && result.job.result.status === 'success') {
                await analyticUsageModel.create('IDENTIFAI-CLOUD-SNAP-OCR-KTP-IDN OCR KTP')

                setFaceId(result.job.result.result[0].nik)
            }

            if (result.job && result.job.result && result.job.result.status === 'incompleted') {
                Swal.fire(
                    "KTP Tidak Dapat Dikenali!",
                    ``,
                    'error'
                )
            }
        } catch (e) {

        }

        setOCRLoading(false)
    }

    useEffect(() => {
        if (Object.keys(nfHeaders).length > 0 && ktpBase64) {
            getOCR()
        }
    }, [nfHeaders])

    const attemptSubmit = async () => {
        if (!faceId) {
            Swal.fire(
                "NIK Tidak Boleh Kosong!",
                ``,
                'error'
            )
        } else {
            try {
                setLoading(true)

                const result = await enrollmentModel.getByNIK(faceId);

                setLoading(false)

                console.log('result', result)

                history.push('person-detail', {data: result})
            } catch (e) {
                setLoading(false)
                if (e.message) {
                    if (e.message.includes("Not found Resident")) {
                        Swal.fire(
                            'Pencarian Gagal!',
                            `NIK ${faceId} tidak ditemukan`,
                            'error'
                        )
                    }
                }

                console.log(e)
            }
        }
    }

    return (
        <Container fluid style={{
            minHeight: '100vh',
            backgroundColor: '#f2f2f2',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Pencarian Data NIK
                </Col>
            </Row>

            <Container style={{marginTop: 20}}>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>NIK</Form.Label>
                                <Form.Control placeholder={'Masukan NIK'}
                                              value={faceId}
                                              onChange={(e) => {
                                                  const re = /^[0-9\b]+$/;

                                                  // if value is not blank, then test the regex

                                                  if (e.target.value === '' || re.test(e.target.value)) {
                                                      setFaceId(e.target.value)
                                                  }
                                              }}
                                />
                            </Form.Group>
                        </Form>

                        <OverlayTrigger
                            placement="top"
                            overlay={(props) => setting.searchByNIK.ocr ? (<></>) :
                                (
                                    <Tooltip id="button-tooltip" {...props}>
                                        OCR untuk Pendaftaran NIK Tidak Aktif
                                    </Tooltip>
                                )}
                        >
                            <div
                                style={{marginTop: 20}}>
                                <Button
                                    disabled={!setting.searchByNIK.ocr || isOCRLoading}
                                    onClick={() => {
                                        history.push('/ktp-image-guidance', {
                                            type: 'ocr-nik-search'
                                        })
                                    }}
                                    style={{...Style.button}}
                                    variant="outline-primary">
                                    {isOCRLoading ? <Spinner animation="border" size={'sm'}/> : 'OCR KTP'}
                                </Button>
                            </div>
                        </OverlayTrigger>

                        <Button
                            disabled={isLoading}
                            onClick={attemptSubmit}
                            style={{...Style.button, marginTop: 15}}
                            variant="primary">
                            {isLoading ? <Spinner animation="border" size={'sm'}/> : 'CARI'}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
