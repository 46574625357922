import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import Resident from "../models/Resident";
import FaceRecognition from "../models/FaceRecognition";
import NFSignature from "../models/NFSignature";
import env from "../util/env";
import * as Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import {BsCheck, BsCheckLg, BsFileEarmarkExcel} from "react-icons/bs";
import Style from "../Style";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import FaceMatchEnrollment from "../models/FaceMatchEnrollment";
import Enrollment from "../models/Enrollment";
import FaceMatch from "../models/FaceMatch";
import {AiOutlineClose} from "react-icons/ai";
import {CgClose} from "react-icons/cg";
import AnalyticUsage from "../models/AnalyticUsage";

export default function NIKValidation(props) {
    const {base64, ktpBase64} = props.location.state;
    const nfSignatureModel = new NFSignature();
    const faceMatchModel = new FaceMatch();
    const enrollmentModel = new Enrollment();
    const [nfHeaders, setNfHeaders] = useState({})
    const analyticUsageModel = new AnalyticUsage();
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    const [faceMatchResult, setFaceMatchResult] = useState({});

    const getNFSignature = async () => {
        try {
            setLoading(true)

            const result = await nfSignatureModel.get();

            setNfHeaders({
                'Content-Type': 'application/json',
                Authorization: `NODEFLUX-HMAC-SHA256 Credential=${env.NF_ACCESS_KEY}/${result.headers["x-nodeflux-timestamp"].substring(0, 8)}/nodeflux.api.v1beta1.ImageAnalytic/StreamImageAnalytic, SignedHeaders=x-nodeflux-timestamp, Signature=${result.token}`,
                'x-nodeflux-timestamp': result.headers["x-nodeflux-timestamp"]
            })

            setLoading(false)
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    }

    const faceMatch = async () => {
        setLoading(true)

        try {
            const result = await faceMatchModel.get(`data:image/jpeg;base64,${base64}`, `data:image/jpeg;base64,${ktpBase64}`, nfHeaders)

            await analyticUsageModel.create("VISIONAIRE-CLOUD-SNAP-FM-FC-2PHT Face Match 2 Photos")

            if (result.result[0].face_match.match) {
                Swal.fire(
                    "Verifikasi Berhasil!",
                    `Foto selfie dengan EKTP cocok dengan keyakinan ${result.result[0].face_match.similarity === 1 ? '99%' : Math.floor(result.result[0].face_match.similarity * 100) + '%'}`,
                    'success'
                )
            } else {
                Swal.fire(
                    "Verifikasi Gagal!",
                    `Foto selfie dengan EKTP tidak cocok`,
                    'error'
                )
            }

            setFaceMatchResult(result)
        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    }

    useEffect(() => {
        getNFSignature()
    }, [])

    useEffect(() => {
        if (Object.keys(nfHeaders).length > 0) {
            faceMatch()
        }
    }, [nfHeaders])

    return (
        <Container fluid>
            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Verifikasi Muka Dengan EKTP
                </Col>
            </Row>

            <Row>
                <Col style={{fontWeight: '600', marginTop: 15}}>
                    Foto EKTP
                </Col>
            </Row>

            <Row>
                <Col style={{boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px', margin: 15, borderRadius: 5}}>
                    <img src={`data:image/jpeg;base64,${ktpBase64}`}
                         style={{width: '100%', height: 200, objectFit: 'contain'}}/>
                </Col>
            </Row>

            <Row>
                <Col style={{fontWeight: '600', marginTop: 15}}>
                    Foto Muka
                </Col>
            </Row>

            <Row>
                <Col style={{boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px', margin: 15, borderRadius: 5}}>
                    <img src={`data:image/jpeg;base64,${base64}`}
                         style={{width: '100%', height: 200, objectFit: 'contain'}}/>
                </Col>
            </Row>

            <Row>
                <Col style={{fontWeight: '600', marginTop: 15}}>
                    Hasil
                </Col>
            </Row>

            {isLoading ?
                <Col style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 15
                }}>
                    <Spinner animation={'border'} variant={'secondary'}/>
                    <div style={{color: 'grey', marginTop: 10}}>
                        Memuat Hasil
                    </div>
                </Col> :
                Object.keys(faceMatchResult).length > 0 ?
                    <>
                        <Row>
                            <Col style={{
                                boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px',
                                margin: 15,
                                borderRadius: 5,
                                padding: 15
                            }}>
                                {
                                    faceMatchResult.result[0].face_match.match ?
                                        <>
                                            <div className={'swal2-icon swal2-success swal2-icon-show'}
                                                 style={{
                                                     display: 'flex',
                                                     alignItems: 'center',
                                                     justifyContent: 'center',
                                                     marginTop: 15
                                                 }}>
                                                <BsCheck size={65}/>
                                            </div>

                                            <div className={'swal2-title'} style={{padding: 0}}>
                                                Verifikasi Berhasil!
                                            </div>

                                            <div className={'swal2-html-container'} style={{margin: 5}}>
                                                {`Foto selfie dengan EKTP cocok dengan keyakinan ${faceMatchResult.result[0].face_match.similarity === 1 ? '99%' : Math.floor(faceMatchResult.result[0].face_match.similarity * 100) + '%'}`}
                                            </div>
                                        </> :
                                        <>
                                            <div className={'swal2-icon swal2-error swal2-icon-show'} style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: 15
                                            }}>
                                                <CgClose size={65}/>
                                            </div>

                                            <div className={'swal2-title'} style={{padding: 0}}>
                                                Verifikasi Gagal!
                                            </div>

                                            <div className={'swal2-html-container'} style={{margin: 5}}>
                                                Foto selfie dengan EKTP tidak cocok
                                            </div>
                                        </>
                                }
                            </Col>
                        </Row>
                    </> : null
            }

        </Container>
    )
}
