import apiConfig from "../util/apiConfig";
import ApiRequest from "../util/ApiRequest";
import env from "../util/env";

export default class Enrollment {
    create = async (body) => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/enrollment`, "POST", body);
    }

    update = async (body) => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/enrollment`, "PUT", body);
    }

    getByNIK = async (nik) => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/enrollment?nik=${nik}`, "GET");
    }
}
