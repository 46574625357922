import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import Resident from "../models/Resident";
import FaceRecognition from "../models/FaceRecognition";
import NFSignature from "../models/NFSignature";
import env from "../util/env";
import * as Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import {BsFileEarmarkExcel} from "react-icons/bs";
import Style from "../Style";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import FaceMatchEnrollment from "../models/FaceMatchEnrollment";
import Enrollment from "../models/Enrollment";
import OCR from "../models/OCR";
import AnalyticUsage from "../models/AnalyticUsage";

export default function NIKValidation(props) {
    const setting = JSON.parse(localStorage.getItem('setting'));
    const {base64, ktpBase64} = props.location.state;
    const nfSignatureModel = new NFSignature();
    const faceMatchEnrollmentModel = new FaceMatchEnrollment();
    const enrollmentModel = new Enrollment();
    const [nfHeaders, setNfHeaders] = useState({})
    const ocrModel = new OCR()
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    const [faceId, setFaceId] = useState('');
    const analyticUsageModel = new AnalyticUsage();

    const getNFSignature = async () => {
        try {
            setLoading(true)

            const result = await nfSignatureModel.get();

            setNfHeaders({
                'Content-Type': 'application/json',
                Authorization: `NODEFLUX-HMAC-SHA256 Credential=${env.NF_ACCESS_KEY}/${result.headers["x-nodeflux-timestamp"].substring(0, 8)}/nodeflux.api.v1beta1.ImageAnalytic/StreamImageAnalytic, SignedHeaders=x-nodeflux-timestamp, Signature=${result.token}`,
                'x-nodeflux-timestamp': result.headers["x-nodeflux-timestamp"]
            })

            setLoading(false)
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    }

    useEffect(() => {
        getNFSignature()
    }, [])

    const getOCR = async () => {
        try {
            setLoading(true)
            const result = await ocrModel.get(`data:image/jpeg;base64,${ktpBase64}`, nfHeaders)

            if (result.job && result.job.result && result.job.result.status === 'on going') {
                getOCR()

                setLoading(false)
            }

            if (result.job && result.job.result && result.job.result.status === 'success') {
                await analyticUsageModel.create('IDENTIFAI-CLOUD-SNAP-OCR-KTP-IDN OCR KTP')

                setFaceId(result.job.result.result[0].nik)

                setLoading(false)
            }

            if (result.job && result.job.result && result.job.result.status === 'incompleted') {
                Swal.fire(
                    "KTP Tidak Dapat Dikenali!",
                    ``,
                    'error'
                )
            }
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (Object.keys(nfHeaders).length > 0 && ktpBase64) {
            getOCR()
        }
    }, [nfHeaders])

    return (
        <Container fluid>
            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Validasi 1:1
                </Col>
            </Row>

            <Row>
                <Col style={{fontWeight: '600', marginTop: 15}}>
                    Foto Input
                </Col>
            </Row>

            <Row>
                <Col style={{boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px', margin: 15, borderRadius: 5}}>
                    <img src={`data:image/jpeg;base64,${base64}`}
                         style={{width: '100%', height: 200, objectFit: 'contain'}}/>
                </Col>
            </Row>

            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label style={{
                        ...Style.formLabel,
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <div style={{flex: 1}}>
                            NIK
                        </div>

                        <OverlayTrigger
                            placement="top"
                            overlay={(props) => setting.verificationWithNIK.ocr ? (<></>) :
                                (
                                    <Tooltip id="button-tooltip" {...props}>
                                        OCR untuk Validasi 1:1 Tidak Aktif
                                    </Tooltip>
                                )}
                        >
                            <div>
                                <a href={'#'}
                                   style={setting.verificationWithNIK.ocr ? {} :
                                       {
                                           color: 'grey',
                                           pointerEvents: 'none'
                                       }}
                                   onClick={(e) => {
                                       e.preventDefault()

                                       history.push('ktp-image-guidance', {
                                           type: '1:1',
                                           base64
                                       })
                                   }}>
                                    (OCR KTP)
                                </a>
                            </div>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                        value={faceId}
                        onChange={e => setFaceId(e.target.value)}
                        placeholder={'Masukan NIK'}/>
                </Form.Group>
            </Form>

            <Button
                onClick={async () => {
                    if (!faceId) {
                        Swal.fire(
                            "NIK Tidak Boleh Kosong!",
                            ``,
                            'error'
                        )
                    } else {
                        try {
                            setLoading(true)

                            const result = await faceMatchEnrollmentModel.get(faceId, `data:image/jpeg;base64,${base64}`, nfHeaders)


                            if (result.ok) {
                                if (result.message === 'face_id is not enrolled') {
                                    Swal.fire(
                                        "Pencarian Gagal!",
                                        `NIK ${faceId} tidak ditemukan`,
                                        'error'
                                    )
                                } else {
                                    if (result.result[0].face_match) {
                                        if (result.result[0].face_match.similarity >= .7) {
                                            Swal.fire(
                                                "Pencarian Berhasil!",
                                                `Foto selfie dengan NIK ${faceId} ditemukan dengan keyakinan ${result.result[0].face_match.similarity === 1 ? '99%' : Math.floor(result.result[0].face_match.similarity * 100) + '%'}`,
                                                'success'
                                            ).then(async res => {
                                                const result = await enrollmentModel.getByNIK(faceId);

                                                setLoading(false)

                                                console.log('result', result)

                                                history.push('person-detail', {data: result})
                                            })
                                        } else {
                                            Swal.fire(
                                                "Pencarian Gagal!",
                                                `NIK ${faceId} dengan foto selfie tidak cocok`,
                                                'error'
                                            )
                                        }
                                    } else {
                                        Swal.fire(
                                            "Pencarian Gagal!",
                                            `NIK ${faceId} dengan foto selfie tidak cocok`,
                                            'error'
                                        )
                                    }
                                    // setFaceMatchResult(result.result[0].face_match)
                                }
                            }

                            setLoading(false)
                        } catch (e) {
                            setLoading(false)

                            console.log(e)
                        }
                    }
                }}
                style={{...Style.button, marginTop: 15}}
                disabled={isLoading}
                variant="primary">
                {
                    isLoading ?
                        <Spinner animation={'border'} size={'sm'}/> :
                        'CARI'
                }
            </Button>
        </Container>
    )
}
