import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Style from "../Style";
import env from "../util/env";
import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";
import Webcam from "react-webcam";
import {IoIosReverseCamera, IoMdTrash} from "react-icons/io";
import {BiChevronRight} from "react-icons/bi";
import * as Swal from "sweetalert2";
import OCR from "../models/OCR";
import NFSignature from "../models/NFSignature";
import FaceLiveness from "../models/FaceLiveness";
import User from "../models/User";

export default function SettingsPage(props) {
    const userModel = new User();

    const MODAL_SETTING_MODE = {
        FACE_RECOGNITION_CANDIDATE_COUNT: 'FACE_RECOGNITION_CANDIDATE_COUNT',
        FACE_RECOGNITION_TRESHOLD: 'FACE_RECOGNITION_TRESHOLD',
        FACE_LIVENESS_TRESHOLD: 'FACE_LIVENESS_TRESHOLD'
    }

    const history = useHistory();
    const [user, setUser] = useState({});
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem('setting')))
    const [tempTreshold, setTempTreshold] = useState(0)
    const [tempNoOfCandidates, setTempNoOfCandidates] = useState(0)
    const [isTresholdModalVisible, setTresholdModalVisible] = useState(false)
    const [isNoOfCandidatesModalVisible, setNoOfCandidatesModalVisible] = useState(false)
    const [modalSettingMode, setModalSettingMode] = useState(null)

    useEffect(() => {
        tokenLogin()
    }, [])

    const tokenLogin = async () => {
        if (localStorage.token) {
            try {
                const result = await userModel.tokenLogin()

                console.log('result', result)

                setUser(result)
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        localStorage.setItem('setting', JSON.stringify(setting))
    }, [setting])

    return (
        <Container fluid style={{
            minHeight: '100vh',
            backgroundColor: '#f2f2f2',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Modal show={isNoOfCandidatesModalVisible} centered onHide={() => {
                setNoOfCandidatesModalVisible(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Jumlah Kandidat</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Select
                        value={tempNoOfCandidates}
                        onChange={(e) => {
                            setTempNoOfCandidates(e.target.value)
                        }}>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={5}>5</option>
                    </Form.Select>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setNoOfCandidatesModalVisible(false)}>Tutup</Button>
                    <Button variant="primary" onClick={() => {


                        const settingTemp = {...setting};

                        if (modalSettingMode === MODAL_SETTING_MODE.FACE_RECOGNITION_CANDIDATE_COUNT) {
                            settingTemp.faceRecognition.candidateCount = parseInt(tempNoOfCandidates)
                        }

                        setSetting(settingTemp)
                        setNoOfCandidatesModalVisible(false)
                    }}>Simpan</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isTresholdModalVisible} centered onHide={() => setTresholdModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Treshold</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Range
                        min={20}
                        max={99}
                        value={tempTreshold}
                        onChange={(e) => {
                            setTempTreshold(e.target.value)
                        }}
                    />
                    <Form.Label style={{display: 'flex', justifyContent: 'center'}}>{tempTreshold}%</Form.Label>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setTresholdModalVisible(false)}>Tutup</Button>
                    <Button variant="primary" onClick={() => {
                        const settingTemp = {...setting};

                        if (modalSettingMode === MODAL_SETTING_MODE.FACE_RECOGNITION_TRESHOLD) {
                            settingTemp.faceRecognition.treshold = tempTreshold / 100;
                        } else if (modalSettingMode === MODAL_SETTING_MODE.FACE_LIVENESS_TRESHOLD) {
                            settingTemp.liveness.treshold = tempTreshold / 100;
                        }

                        setSetting(settingTemp)
                        setTresholdModalVisible(false)
                    }}>Simpan</Button>
                </Modal.Footer>
            </Modal>

            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Pengaturan
                </Col>
            </Row>

            <Container style={{marginTop: 20}}>
                <Row style={{
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 'rgb(136, 136, 136, .1) 0px 0px 3px 1px',
                    padding: 20
                }}>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <img src={user.photo ? user.photo : 'no-img-placeholder.png'}
                             style={{
                                 width: 100,
                                 height: 100,
                                 objectFit: user.photo ? 'cover' : 'contain',
                                 backgroundColor: '#f2f2f2',
                                 borderRadius: 100,
                                 display: 'block'
                             }}/>

                        <h3 style={{marginTop: 10}}>
                            {user.name}
                        </h3>

                        <div style={{color: 'grey', fontSize: '.9em'}}>
                            {user.email}
                        </div>

                        {/*<div style={{color: 'grey', fontSize: '.9em'}}>*/}
                        {/*    +49 (0)341 69764021*/}
                        {/*</div>*/}

                        {/*<div style={{color: 'grey', fontSize: '.9em'}}>*/}
                        {/*    Ralf Rangnick Stiftung*/}
                        {/*</div>*/}
                    </Col>
                </Row>

                <Row>
                    <Col style={{
                        color: '#949494',
                        fontWeight: '600',
                        fontSize: '.85em',
                        marginTop: 12,
                        marginBottom: 12
                    }}>
                        PENDAFTARAN WAJAH
                    </Col>
                </Row>

                <Row style={{
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 'rgb(136, 136, 136, .1) 0px 0px 3px 1px',
                    padding: 20,
                    fontWeight: '600',
                    fontSize: '.95em'
                }}>
                    <Col xs={12} style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>
                            Face Liveness
                        </div>

                        <Form.Check
                            type="switch"
                            onChange={() => {
                                const settingTemp = {...setting};

                                settingTemp.enrollment.liveness = !settingTemp.enrollment.liveness

                                setSetting(settingTemp)
                            }}
                            checked={setting.enrollment.liveness}
                        />
                    </Col>

                    <Col xs={12} style={{display: 'flex', flexDirection: 'row', marginTop: 30}}>
                        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>
                            OCR
                        </div>

                        <Form.Check
                            type="switch"
                            onChange={() => {
                                const settingTemp = {...setting};

                                settingTemp.enrollment.ocr = !settingTemp.enrollment.ocr

                                setSetting(settingTemp)
                            }}
                            checked={setting.enrollment.ocr}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col style={{
                        color: '#949494',
                        fontWeight: '600',
                        fontSize: '.85em',
                        marginTop: 12,
                        marginBottom: 12
                    }}>
                        VALIDASI 1:N
                    </Col>
                </Row>

                <Row style={{
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 'rgb(136, 136, 136, .1) 0px 0px 3px 1px',
                    padding: 20,
                    fontWeight: '600',
                    fontSize: '.95em'
                }}>
                    <Col xs={12} style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>
                            Face Liveness
                        </div>

                        <Form.Check
                            type="switch"
                            onChange={() => {
                                const settingTemp = {...setting};

                                settingTemp.faceRecognition.liveness = !settingTemp.faceRecognition.liveness

                                setSetting(settingTemp)
                            }}
                            checked={setting.faceRecognition.liveness}
                        />
                    </Col>

                    <Col xs={12}
                         onClick={() => {
                             setNoOfCandidatesModalVisible(true)
                             setTempNoOfCandidates(setting.faceRecognition.candidateCount)

                             setModalSettingMode(MODAL_SETTING_MODE.FACE_RECOGNITION_CANDIDATE_COUNT)
                         }}
                         style={{display: 'flex', flexDirection: 'row', marginTop: 30, alignItems: 'center'}}>
                        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>
                            Jumlah Kandidat
                        </div>

                        <div style={{color: 'grey', marginRight: 5, fontSize: '.95em'}}>
                            {setting.faceRecognition.candidateCount}
                        </div>

                        <BiChevronRight size={25}/>
                    </Col>

                    <Col xs={12}
                         onClick={() => {
                             setTempTreshold(setting.faceRecognition.treshold * 100)
                             setModalSettingMode(MODAL_SETTING_MODE.FACE_RECOGNITION_TRESHOLD)
                             setTresholdModalVisible(true)
                         }}
                         style={{display: 'flex', flexDirection: 'row', marginTop: 30, alignItems: 'center'}}>
                        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>
                            Treshold
                        </div>

                        <div style={{color: 'grey', marginRight: 5, fontSize: '.95em'}}>
                            {setting.faceRecognition.treshold * 100}%
                        </div>

                        <BiChevronRight size={25}/>
                    </Col>
                </Row>

                {/*<Row>*/}
                {/*    <Col style={{*/}
                {/*        color: '#949494',*/}
                {/*        fontWeight: '600',*/}
                {/*        fontSize: '.85em',*/}
                {/*        marginTop: 12,*/}
                {/*        marginBottom: 12*/}
                {/*    }}>*/}
                {/*        VALIDASI 1:1*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row style={{*/}
                {/*    backgroundColor: 'white',*/}
                {/*    borderRadius: 5,*/}
                {/*    boxShadow: 'rgb(136, 136, 136, .1) 0px 0px 3px 1px',*/}
                {/*    padding: 20,*/}
                {/*    fontWeight: '600',*/}
                {/*    fontSize: '.95em'*/}
                {/*}}>*/}
                {/*    <Col xs={12} style={{display: 'flex', flexDirection: 'row'}}>*/}
                {/*        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>*/}
                {/*            Face Liveness*/}
                {/*        </div>*/}

                {/*        <Form.Check*/}
                {/*            type="switch"*/}
                {/*            onChange={() => {*/}
                {/*                const settingTemp = {...setting};*/}

                {/*                settingTemp.verificationWithNIK.liveness = !settingTemp.verificationWithNIK.liveness*/}

                {/*                setSetting(settingTemp)*/}
                {/*            }}*/}
                {/*            checked={setting.verificationWithNIK.liveness}*/}
                {/*        />*/}
                {/*    </Col>*/}

                {/*    <Col xs={12} style={{display: 'flex', flexDirection: 'row', marginTop: 30}}>*/}
                {/*        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>*/}
                {/*            OCR*/}
                {/*        </div>*/}

                {/*        <Form.Check*/}
                {/*            type="switch"*/}
                {/*            onChange={() => {*/}
                {/*                const settingTemp = {...setting};*/}

                {/*                settingTemp.verificationWithNIK.ocr = !settingTemp.verificationWithNIK.ocr*/}

                {/*                setSetting(settingTemp)*/}
                {/*            }}*/}
                {/*            checked={setting.verificationWithNIK.ocr}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row>*/}
                {/*    <Col style={{*/}
                {/*        color: '#949494',*/}
                {/*        fontWeight: '600',*/}
                {/*        fontSize: '.85em',*/}
                {/*        marginTop: 12,*/}
                {/*        marginBottom: 12*/}
                {/*    }}>*/}
                {/*        PENCARIAN DATA NIK*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row style={{*/}
                {/*    backgroundColor: 'white',*/}
                {/*    borderRadius: 5,*/}
                {/*    boxShadow: 'rgb(136, 136, 136, .1) 0px 0px 3px 1px',*/}
                {/*    padding: 20,*/}
                {/*    fontWeight: '600',*/}
                {/*    fontSize: '.95em'*/}
                {/*}}>*/}
                {/*    <Col xs={12} style={{display: 'flex', flexDirection: 'row'}}>*/}
                {/*        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>*/}
                {/*            OCR*/}
                {/*        </div>*/}

                {/*        <Form.Check*/}
                {/*            type="switch"*/}
                {/*            onChange={() => {*/}
                {/*                const settingTemp = {...setting};*/}

                {/*                settingTemp.searchByNIK.ocr = !settingTemp.searchByNIK.ocr*/}

                {/*                setSetting(settingTemp)*/}
                {/*            }}*/}
                {/*            checked={setting.searchByNIK.ocr}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row>*/}
                {/*    <Col style={{*/}
                {/*        color: '#949494',*/}
                {/*        fontWeight: '600',*/}
                {/*        fontSize: '.85em',*/}
                {/*        marginTop: 12,*/}
                {/*        marginBottom: 12*/}
                {/*    }}>*/}
                {/*        Verifikasi Muka Dengan EKTP*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row style={{*/}
                {/*    backgroundColor: 'white',*/}
                {/*    borderRadius: 5,*/}
                {/*    boxShadow: 'rgb(136, 136, 136, .1) 0px 0px 3px 1px',*/}
                {/*    padding: 20,*/}
                {/*    fontWeight: '600',*/}
                {/*    fontSize: '.95em'*/}
                {/*}}>*/}
                {/*    <Col xs={12} style={{display: 'flex', flexDirection: 'row'}}>*/}
                {/*        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>*/}
                {/*            Face Liveness*/}
                {/*        </div>*/}

                {/*        <Form.Check*/}
                {/*            type="switch"*/}
                {/*            onChange={() => {*/}
                {/*                const settingTemp = {...setting};*/}

                {/*                settingTemp.verificationWithKTP.liveness = !settingTemp.verificationWithKTP.liveness*/}

                {/*                setSetting(settingTemp)*/}
                {/*            }}*/}
                {/*            checked={setting.verificationWithKTP.liveness}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row>*/}
                {/*    <Col style={{*/}
                {/*        color: '#949494',*/}
                {/*        fontWeight: '600',*/}
                {/*        fontSize: '.85em',*/}
                {/*        marginTop: 12,*/}
                {/*        marginBottom: 12*/}
                {/*    }}>*/}
                {/*        Face Liveness*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row style={{*/}
                {/*    backgroundColor: 'white',*/}
                {/*    borderRadius: 5,*/}
                {/*    boxShadow: 'rgb(136, 136, 136, .1) 0px 0px 3px 1px',*/}
                {/*    padding: 20,*/}
                {/*    fontWeight: '600',*/}
                {/*    fontSize: '.95em',*/}
                {/*    marginBottom: 30*/}
                {/*}}>*/}
                {/*    <Col xs={12}*/}
                {/*         onClick={() => {*/}
                {/*             setTempTreshold(setting.liveness.treshold * 100)*/}
                {/*             setModalSettingMode(MODAL_SETTING_MODE.FACE_LIVENESS_TRESHOLD)*/}
                {/*             setTresholdModalVisible(true)*/}
                {/*         }}*/}
                {/*         style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
                {/*        <div style={{flex: 1, color: '#404040', fontSize: '.95em'}}>*/}
                {/*            Treshold*/}
                {/*        </div>*/}

                {/*        <div style={{color: 'grey', marginRight: 5, fontSize: '.95em'}}>*/}
                {/*            {setting.liveness.treshold * 100}%*/}
                {/*        </div>*/}

                {/*        <BiChevronRight size={25}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Container>
        </Container>
    )
}
