import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Style from "../Style";
import moment from "moment";
import {Button} from "react-bootstrap";
import React from "react";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";

export default function SearchByNIKPage(props) {
    const {data} = props.location.state
    const history = useHistory();

    return (
        <Container fluid style={{
            minHeight: '100vh',
            backgroundColor: '#f2f2f2',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Pencarian
                </Col>
            </Row>

            <Container style={{marginTop: 20, marginBottom: 20}}>
                <Row>
                    <Col style={Style.card}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={data.enrolled_face}
                                 style={{width: 70, height: 70, borderRadius: 40, objectFit: 'cover'}}/>

                            <div style={{
                                marginLeft: 15,
                                fontSize: 16,
                                fontWeight: '700'
                            }}>{data.full_name.toUpperCase()}</div>
                        </div>

                        <div style={{height: 1, backgroundColor: '#e0e0e0', marginTop: 20, marginBottom: 20}}/>

                        <div style={{fontWeight: '700', fontSize: 13}}>
                            NIK
                        </div>

                        <div>
                            {data.nik}
                        </div>

                        <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                            Provinsi
                        </div>

                        <div>
                            {data.province ? data.province : '-'}
                        </div>

                        <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                            Kota/Kabupaten
                        </div>

                        <div>
                            {data.district ? data.district : '-'}
                        </div>

                        <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                            Tempat Lahir
                        </div>

                        <div>
                            {data.place_of_birth ? data.place_of_birth : '-'}
                        </div>

                        <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                            Tanggal Lahir
                        </div>

                        <div>
                            {data.date_of_birth ? moment(data.date_of_birth).format('DD-MM-YYYY') : '-'}
                        </div>

                        <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                            Jenis Kelamin
                        </div>

                        <div>
                            {data.gender === 'M' ? 'Pria' : data.gender === 'F' ? 'Perempuan' : '-'}
                        </div>

                        {/*<Button*/}
                        {/*    style={{...Style.button, marginTop: 25}}*/}
                        {/*    variant="outline-primary">REQUEST PERUBAHAN DATA</Button>*/}

                        <Button
                            onClick={history.goBack}
                            style={{...Style.button, marginTop: 25}}
                            variant="primary">OK</Button>
                    </Col>
                </Row>


            </Container>
        </Container>
    )
}