import env from "../util/env";
import apiConfig from "../util/apiConfig";
import ApiRequest from "../util/ApiRequest";

export default class FaceLiveness {
    get = async (base64, headers) => {
        const setting = JSON.parse(localStorage.getItem('setting'));

        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/nf-cloud/face-liveness`, "POST", {
            images: [base64],
            additional_params: {
                liveness_threshold: setting.liveness.treshold
            }
        }, headers);
    }
}
