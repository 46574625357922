import '../App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import Style from "../Style";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Select, {components} from "react-select";
import Location from "../models/Location";
import Enrollment from "../models/Enrollment";
import {MdOutlineArrowBack} from "react-icons/md";
import * as Swal from "sweetalert2";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import env from "../util/env";
import OCR from "../models/OCR";
import NFSignature from "../models/NFSignature";
import moment from "moment";
import AnalyticUsage from "../models/AnalyticUsage";

export default function EnrollmentPage(props) {
    const setting = JSON.parse(localStorage.getItem('setting'));
    const [nfHeaders, setNfHeaders] = useState({})
    const ocrModel = new OCR()
    const nfSignatureModel = new NFSignature()
    const {base64, ktpBase64, attempt} = props.location.state;

    const locationModel = new Location();
    const enrollmentModel = new Enrollment();
    const [isOCRLoading, setOCRLoading] = useState(false);
    const [isRegisterLoading, setRegisterLoading] = useState(false);
    const [provinces, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const history = useHistory()
    const [enrollmentData, setEnrollmentData] = useState({enrolled_face: `data:image/jpeg;base64,${base64}`})
    const analyticUsageModel = new AnalyticUsage();

    const getNFSignature = async () => {
        try {
            setOCRLoading(true)

            const result = await nfSignatureModel.get();

            setNfHeaders({
                'Content-Type': 'application/json',
                Authorization: `NODEFLUX-HMAC-SHA256 Credential=${env.NF_ACCESS_KEY}/${result.headers["x-nodeflux-timestamp"].substring(0, 8)}/nodeflux.api.v1beta1.ImageAnalytic/StreamImageAnalytic, SignedHeaders=x-nodeflux-timestamp, Signature=${result.token}`,
                'x-nodeflux-timestamp': result.headers["x-nodeflux-timestamp"]
            })
        } catch (e) {
            setOCRLoading(false)

            console.log(e)
        }
    }

    const getOCR = async () => {
        try {
            const result = await ocrModel.get(`data:image/jpeg;base64,${ktpBase64}`, nfHeaders)

            if (result.job && result.job.result && result.job.result.status === 'on going') {
                return getOCR()
            }

            if (result.job && result.job.result && result.job.result.status === 'success') {
                const data = result.job.result.result[0]

                await analyticUsageModel.create('IDENTIFAI-CLOUD-SNAP-OCR-KTP-IDN OCR KTP')

                setEnrollmentData({
                    ...enrollmentData,
                    nik: data.nik,
                    full_name: data.nama,
                    place_of_birth: data.tempat_lahir,
                    date_of_birth: moment(data.tanggal_lahir, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    gender: data.jenis_kelamin === 'LAKI-LAKI' ? 'M' : 'F',
                    province: data.provinsi,
                    district: data.kabupaten_kota,
                    from_ocr: true
                })
            }

            if (result.job && result.job.result && result.job.result.status === 'incompleted') {
                Swal.fire(
                    "KTP Tidak Dapat Dikenali!",
                    `Harap unggah foto KTP kembali.`,
                    'error'
                )
            }
        } catch (e) {
        }

        setOCRLoading(false)
    }

    useEffect(() => {
        if (ktpBase64) {
            if(props.history.action === "POP") {
                history.goBack()
            } else {
                getNFSignature()
            }
        } else {
            const threeChances = sessionStorage.getItem("three-chances") === "true";

            if(threeChances) {
                sessionStorage.removeItem("three-chances")
                history.goBack();
            }
        }
    }, [])

    useEffect(() => {
        if (Object.keys(nfHeaders).length > 0) {
            getOCR()
        }
    }, [nfHeaders])

    const attemptUpdate = async () => {
        setRegisterLoading(true)

        try {
            const response = await enrollmentModel.update(enrollmentData)

            setRegisterLoading(false)
            Swal.fire(
                "Wajah Berhasil Terupdate!",
                ``,
                'success'
            ).then(() => {
                history.push('/home')
            })
            console.log(response)
        } catch (e) {
            Swal.fire(
                "Wajah Gagal Terupdate!",
                `Harap periksa foto wajah yang ingin didaftarkan.`,
                'error'
            )
            setRegisterLoading(false)
            console.log(e)
        }
    }

    const attemptSubmit = async () => {
        setRegisterLoading(true)

        try {
            const response = await enrollmentModel.create(enrollmentData)

            await analyticUsageModel.create("VISIONAIRE-CLOUD-SNAP-FE-FC Face Enrollment (Cloud)")

            setRegisterLoading(false)

            Swal.fire(
                "Pendaftaran Wajah Berhasil!",
                ``,
                'success'
            ).then(() => {
                history.push('/home')
            })
            console.log(response)
        } catch (e) {
            if (e.message) {
                if (e.message === 'DUPLICATE_NIK') {
                    Swal.fire({
                        title: 'NIK Telah Terdaftar!',
                        icon: 'error',
                        showCancelButton: true,
                        confirmButtonText: 'Overwrite',
                    }).then(result => {
                        if (result.isConfirmed) {
                            attemptUpdate()
                        }
                    })
                } else if (e.message.job_id) {
                    if (e.message.result.length === 0) {
                        Swal.fire(
                            "Tidak Ada Muka Terdeteksi!",
                            ``,
                            'error'
                        )
                    }
                }
            }

            setRegisterLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        if (provinces.length > 0 && enrollmentData.from_ocr) {
            provinces.forEach(province => {
                if (province.label.toLowerCase() === enrollmentData.province?.toLowerCase()) {
                    getCities(province.value)
                }
            })
        }
    }, [enrollmentData, provinces])

    const getProvinces = async () => {
        try {
            const response = await locationModel.getAllProvince();

            if (response.rajaongkir.results) {
                let provincesTemp = response.rajaongkir.results.map(result => {
                    return {
                        value: result.province_id,
                        label: result.province
                    }
                })

                setProvinces(provincesTemp)
            }
            console.log(response)
        } catch (e) {
            console.log(e)
        }
    }

    const getCities = async (id) => {
        try {
            const response = await locationModel.getCityById(id);

            if (response.rajaongkir.results) {
                let citiesTemp = response.rajaongkir.results.map(result => {
                    return {
                        value: result.city_id,
                        label: `${result.type} ${result.city_name}`
                    }
                })

                setCities(citiesTemp)
            }
            console.log(response)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getProvinces()
    }, [])

    return (
        <Container fluid style={{
            minHeight: '100vh',
            backgroundColor: '#f2f2f2',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Pendaftaran Wajah
                </Col>
            </Row>

            <Container style={{marginTop: 20}}>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>Foto</Form.Label>
                                <Col style={{
                                    boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px',
                                    margin: 15,
                                    borderRadius: 5,
                                    backgroundColor: 'white'
                                }}>
                                    <img src={`data:image/jpeg;base64,${base64}`}
                                         style={{width: '100%', height: 200, objectFit: 'contain'}}/>
                                </Col>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>NIK *</Form.Label>
                                <Form.Control placeholder={'Masukan NIK'}
                                              value={enrollmentData.nik}
                                              disabled={enrollmentData.from_ocr}
                                              onChange={(e) => {
                                                  const re = /^[0-9\b]+$/;

                                                  // if value is not blank, then test the regex

                                                  if (e.target.value === '' || re.test(e.target.value)) {
                                                      setEnrollmentData({
                                                          ...enrollmentData,
                                                          nik: e.target.value
                                                      })
                                                  }
                                              }}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>Nama *</Form.Label>
                                <Form.Control placeholder={'Masukan Nama'}
                                              value={enrollmentData.full_name}
                                              onChange={(e) => {
                                                  setEnrollmentData({
                                                      ...enrollmentData,
                                                      full_name: e.target.value
                                                  })
                                              }}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>Tempat Lahir</Form.Label>
                                <Form.Control placeholder={'Masukan Tempat Lahir'}
                                              value={enrollmentData.place_of_birth}
                                              onChange={(e) => {
                                                  setEnrollmentData({
                                                      ...enrollmentData,
                                                      place_of_birth: e.target.value
                                                  })
                                              }}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>Tanggal Lahir</Form.Label>
                                <Form.Control placeholder={'Masukan Tanggal Lahir'}
                                              value={enrollmentData.date_of_birth}
                                              type={'date'}
                                              onChange={(e) => {
                                                  setEnrollmentData({
                                                      ...enrollmentData,
                                                      date_of_birth: e.target.value
                                                  })
                                              }}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>Gender</Form.Label>
                                <div>
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => {
                                                setEnrollmentData({
                                                    ...enrollmentData,
                                                    gender: 'M'
                                                })
                                            }}
                                            variant={enrollmentData.gender === 'M' ? 'primary' : 'outline-primary'}
                                            style={{width: 105}}>
                                            Pria
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setEnrollmentData({
                                                    ...enrollmentData,
                                                    gender: 'F'
                                                })
                                            }}
                                            variant={enrollmentData.gender === 'F' ? 'primary' : 'outline-primary'}>
                                            Perempuan
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>Provinsi</Form.Label>
                                <Select
                                    options={provinces}
                                    value={provinces.filter(province => province.label.toLowerCase() === enrollmentData.province?.toLowerCase())}
                                    onChange={(e) => {
                                        getCities(e.value)
                                        setEnrollmentData({
                                            ...enrollmentData,
                                            province: e.label,
                                            district: null
                                        })
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={Style.formLabel}>Kabupaten/Kota</Form.Label>
                                <Select
                                    options={cities}
                                    value={cities.filter(city => city.label.toLowerCase() === enrollmentData.district?.toLowerCase())}
                                    onChange={(e) => {
                                        setEnrollmentData({
                                            ...enrollmentData,
                                            district: e.label
                                        })
                                    }}
                                />
                            </Form.Group>
                        </Form>

                        <OverlayTrigger
                            placement="top"
                            overlay={(props) => setting.enrollment.ocr ? (<></>) :
                                (
                                    <Tooltip id="button-tooltip" {...props}>
                                        OCR untuk Pendaftaran Wajah Tidak Aktif
                                    </Tooltip>
                                )}
                        >
                            <div
                                style={{marginTop: 20}}>
                                <Button
                                    disabled={!setting.enrollment.ocr || isOCRLoading}
                                    onClick={() => {
                                        if(attempt === 3) {
                                            Swal.fire(
                                                "Anda telah melakukan OCR KTP sebanyak 3x, harap melakukan pengambilan foto ulang.",
                                                ``,
                                                'error'
                                            ).then(() => {
                                                sessionStorage.setItem("three-chances", "true")
                                                history.goBack()
                                            })
                                        } else {
                                            history.push('/ktp-image-guidance', {
                                                type: 'ocr-enrollment',
                                                base64,
                                                attempt: attempt ? attempt + 1 : 1
                                            })
                                        }
                                    }}
                                    style={{...Style.button}}
                                    variant="outline-primary">
                                    {isOCRLoading ? <Spinner animation="border" size={'sm'}/> : 'OCR KTP'}
                                </Button>
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="top"
                            overlay={(props) => enrollmentData.nik && enrollmentData.full_name ? (<></>) :
                                (
                                    <Tooltip id="button-tooltip" {...props}>
                                        Harap Mengisi NIK dan Nama
                                    </Tooltip>
                                )}
                        >
                            <div>
                                <Button
                                    disabled={isRegisterLoading || !enrollmentData.nik || !enrollmentData.full_name}
                                    onClick={attemptSubmit}
                                    style={{...Style.button, marginTop: 10, marginBottom: 30}}
                                    variant="primary">
                                    {isRegisterLoading ? <Spinner animation="border" size={'sm'}/> : 'DAFTAR'}
                                </Button>
                            </div>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
