
import env from "../util/env";
import apiConfig from "../util/apiConfig";
import ApiRequest from "../util/ApiRequest";

export default class FaceRecognition {
    get = async (base64, headers) => {
        const setting = JSON.parse(localStorage.getItem('setting'));

        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/nf-cloud/face-recognition`, "POST", {
            images: [base64],
            additional_params: {
                "candidate_count": setting.faceRecognition.candidateCount,
                "similarity_threshold": setting.faceRecognition.treshold,
            }
        }, headers);
    }
}
