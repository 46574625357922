import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Style from "../Style";
import React, {useEffect} from "react";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";

export default function KTPImageGuidancePage(props) {
    const history = useHistory();

    useEffect(() => {
        if(!props.location.state) {
            history.goBack();
        }
    }, [])

    return (
        <Container fluid style={{paddingBottom: 80}}>

            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    OCR KTP
                </Col>
            </Row>

            <Row>
                <Col style={Style.footer}>
                   <Button
                       style={{...Style.button, width: '100%'}}
                        onClick={() => history.replace('/camera', props.location.state)}
                   >
                       AMBIL FOTO
                   </Button>
                </Col>
            </Row>

            <Row style={{marginBottom: 20}}>
                <Col style={{fontWeight: '600', fontSize: '1.1em', marginTop: 20}}>
                    PANDUAN FOTO KTP
                </Col>
            </Row>

            <Row>
                <Col xs={1}>
                    1.
                </Col>
                <Col>
                    <div style={{marginBottom: 15}}>
                        Disarankan untuk memotret dalam jarak dekat, untuk jarak yang lebih jauh tidak disarankan karena mengurangi kejelasan karakter pada KTP.
                    </div>
                    <img src={"/guidance-1.png"} style={{width: '100%'}}/>
                    <img src={"/guidance-2.png"} style={{width: '100%'}}/>
                </Col>
            </Row>

            <Row style={{marginTop: 20}}>
                <Col xs={1}>
                    2.
                </Col>
                <Col>
                    <div style={{marginBottom: 15}}>
                        Ambil foto KTP dalam posisi horizontal.
                    </div>
                    <img src={"/guidance-3.png"} style={{width: '100%'}}/>
                </Col>
            </Row>

            <Row style={{marginTop: 20}}>
                <Col xs={1}>
                    3.
                </Col>
                <Col>
                    <div style={{marginBottom: 15}}>
                        Hindari pengambilan foto dengan pantulan cahaya pada KTP karena akan membuat data tidak terbaca.
                    </div>
                    <img src={"/guidance-4.png"} style={{width: '100%'}}/>
                </Col>
            </Row>
        </Container>
    )
}
