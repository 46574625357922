import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Style from "../Style";
import moment from "moment";
import {Button} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";
import OCR from "../models/OCR";
import env from "../util/env";
import NFSignature from "../models/NFSignature";
import * as Swal from "sweetalert2";
import {BsFileEarmarkExcel} from "react-icons/bs";
import Spinner from "react-bootstrap/Spinner";
import ReactToPrint from "react-to-print";
import AnalyticUsage from "../models/AnalyticUsage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function OCRResultPage(props) {
    const ref = useRef(null);
    const {base64} = props.location.state
    const history = useHistory();
    const [data, setData] = useState({})
    const [nfHeaders, setNfHeaders] = useState({})
    const [isLoading, setLoading] = useState(false)
    const ocrModel = new OCR()
    const nfSignatureModel = new NFSignature()
    const analyticUsageModel = new AnalyticUsage();

    const getNFSignature = async () => {
        try {
            setLoading(true)

            const result = await nfSignatureModel.get();

            setNfHeaders({
                'Content-Type': 'application/json',
                Authorization: `NODEFLUX-HMAC-SHA256 Credential=${env.NF_ACCESS_KEY}/${result.headers["x-nodeflux-timestamp"].substring(0, 8)}/nodeflux.api.v1beta1.ImageAnalytic/StreamImageAnalytic, SignedHeaders=x-nodeflux-timestamp, Signature=${result.token}`,
                'x-nodeflux-timestamp': result.headers["x-nodeflux-timestamp"]
            })
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    }

    const getOCR = async () => {
        try {
            setLoading(true)
            const result = await ocrModel.get(`data:image/jpeg;base64,${base64}`, nfHeaders)

            console.log('resultresult', result)

            if (result.job && result.job.result && result.job.result.status === 'on going') {
                getOCR()

                setLoading(false)
            }

            if (result.job && result.job.result && result.job.result.status === 'success') {
                await analyticUsageModel.create('IDENTIFAI-CLOUD-SNAP-OCR-KTP-IDN OCR KTP')

                setData(result.job.result.result[0])

                setLoading(false)
            }

            if (result.job && result.job.result && result.job.result.status === 'incompleted') {
                Swal.fire(
                    "KTP Tidak Dapat Dikenali!",
                    `Harap unggah foto KTP kembali.`,
                    'error'
                ).then(() => history.goBack())
            }
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getNFSignature()
    }, [])

    useEffect(() => {
        if (Object.keys(nfHeaders).length > 0) {
            getOCR()
        }
    }, [nfHeaders])

    return (
        <div ref={ref} id={'divToPrint'}>
            <Container fluid
                       style={{
                           minHeight: '100vh',
                           backgroundColor: '#f2f2f2',
                           display: 'flex',
                           flexDirection: 'column'
                       }}>
                <Row>
                    <Col style={Style.header}>
                        <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                        Hasil OCR KTP
                    </Col>
                </Row>

                <Container style={{marginTop: 20, marginBottom: 20}}>
                    <Row>
                        <Col style={{fontWeight: '600', marginTop: 15}}>
                            Foto Input
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            marginTop: 15,
                            marginBottom: 15
                        }}>
                            <img src={`data:image/jpeg;base64,${base64}`}
                                 style={{width: '100%', height: 200, objectFit: 'contain'}}/>
                        </Col>
                    </Row>

                    {isLoading ?
                        <Col style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 15
                        }}>
                            <Spinner animation={'border'} variant={'secondary'}/>
                            <div style={{color: 'grey', marginTop: 10}}>
                                Memuat Hasil
                            </div>
                        </Col> :
                        Object.keys(data).length > 0 ?
                            <Row>
                                <Col xs={12} style={{fontWeight: '600', marginTop: 15, marginBottom: 15}}>
                                    Hasil
                                </Col>

                                <Col style={Style.card}>
                                    <div style={{fontWeight: '700', fontSize: 13}}>
                                        NIK
                                    </div>

                                    <div>
                                        {data.nik}
                                    </div>

                                    <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                                        Nama
                                    </div>

                                    <div>
                                        {data.nama}
                                    </div>

                                    <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                                        Tempat/Tanggal Lahir
                                    </div>

                                    <div>
                                        {data.tempat_lahir}, {data.tanggal_lahir}
                                    </div>

                                    <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                                        Kota/Kabupaten
                                    </div>

                                    <div>
                                        {data.kabupaten_kota ? data.kabupaten_kota : '-'}
                                    </div>

                                    <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                                        Provinsi
                                    </div>

                                    <div>
                                        {data.provinsi ? data.provinsi : '-'}
                                    </div>

                                    <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                                        Pekerjaan
                                    </div>

                                    <div>
                                        {data.pekerjaan ? data.pekerjaan : '-'}
                                    </div>

                                    <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                                        Kewarganegaraan
                                    </div>

                                    <div>
                                        {data.kewarganegaraan ? data.kewarganegaraan : '-'}
                                    </div>

                                    <div style={{fontWeight: '700', fontSize: 13, marginTop: 15}}>
                                        Status Perkawinan
                                    </div>

                                    <div>
                                        {data.status_perkawinan ? data.status_perkawinan : '-'}
                                    </div>

                                    {/*<Button*/}
                                    {/*    style={{...Style.button, marginTop: 25}}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        const input = document.getElementById('divToPrint');*/}
                                    {/*        html2canvas(input)*/}
                                    {/*            .then((canvas) => {*/}
                                    {/*                const imgData = canvas.toDataURL('image/png');*/}

                                    {/*                const pdf = new jsPDF("p", "mm", "a4");*/}
                                    {/*                const width = pdf.internal.pageSize.getWidth();*/}
                                    {/*                const height = pdf.internal.pageSize.getHeight();*/}

                                    {/*                pdf.addImage(imgData, 'JPEG', 0, 0, width, height);*/}
                                    {/*                pdf.save(`OCR Result - ${data.nama}.pdf`);*/}
                                    {/*            })*/}
                                    {/*        ;*/}
                                    {/*    }}*/}
                                    {/*    variant="outline-primary">DOWNLOAD PDF</Button>*/}
                                    {/*<ReactToPrint*/}
                                    {/*    documentTitle={`OCR Result - ${data.name}`}*/}
                                    {/*    trigger={() => {*/}
                                    {/*        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop*/}
                                    {/*        // to the root node of the returned component as it will be overwritten.*/}
                                    {/*        return (*/}
                                    {/*            <Button*/}
                                    {/*                style={{...Style.button, marginTop: 25}}*/}
                                    {/*                variant="outline-primary">DOWNLOAD PDF</Button>*/}
                                    {/*        )*/}
                                    {/*    }}*/}
                                    {/*    content={() => ref.current}*/}
                                    {/*/>*/}


                                    <Button
                                        onClick={history.goBack}
                                        style={{...Style.button, marginTop: 25}}
                                        variant="primary">OK</Button>
                                </Col>
                            </Row> :
                            null
                    }
                </Container>
            </Container>
        </div>
    )
}
