import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
// import Resident from "../models/Resident";
import Enrollment from "../models/Enrollment";
import FaceRecognition from "../models/FaceRecognition";
import NFSignature from "../models/NFSignature";
import env from "../util/env";
import * as Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import {BsFileEarmarkExcel} from "react-icons/bs";
import Style from "../Style";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";
import AnalyticUsage from "../models/AnalyticUsage";

export default function FaceRecognitionPage(props) {
    const {base64} = props.location.state;
    const nfSignatureModel = new NFSignature();
    const faceRecognitionModel = new FaceRecognition();
    const analyticUsageModel = new AnalyticUsage();
    const enrollmentModel = new Enrollment();
    const [nfHeaders, setNfHeaders] = useState({})
    const [candidates, setCandidates] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()

    const getFaceRecognition = async () => {
        try {
            const result = await faceRecognitionModel.get(`data:image/jpeg;base64,${base64}`, nfHeaders)

            await analyticUsageModel.create("VISIONAIRE-CLOUD-SNAP-FR-FC-FRT Face Recognition Frontal Cloud")

            // const {candidates} = result.result[0].face_recognition[0]

            if (result.length === 0) {
                Swal.fire(
                    "Foto Tidak Dapat Dikenali!",
                    `Tidak ada data yang cocok pada database`,
                    'error'
                )
            } else {
                setCandidates(result)
                // const nikArray = [];
                // let duplicateIdx = []
                //
                // candidates.forEach((candidate, idx) => {
                //     if (nikArray.includes(parseInt(candidate.face_id))) {
                //         duplicateIdx.push(idx)
                //     } else {
                //         nikArray.push(parseInt(candidate.face_id))
                //     }
                // })
                //
                // duplicateIdx = duplicateIdx.reverse();
                //
                // duplicateIdx.forEach(idx => {
                //     candidates.splice(idx, 1)
                // })
                //
                //
                // const residents = await enrollmentModel.getByNIK(JSON.stringify(nikArray));
                //
                // console.log('residentsresidents', residents)
                //
                // candidates.forEach((candidate, idx) => {
                //     residents.forEach(resident => {
                //         if (resident.nik === parseInt(candidate.face_id)) {
                //             const additional_info = resident
                //
                //             candidates[idx] = {
                //                 ...candidates[idx],
                //                 additional_info
                //             };
                //         }
                //     })
                //
                //     console.log('candidatescandidates', candidates)
                //
                //     if (idx === candidates.length - 1) {
                //
                //     }
                // })
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    const getNFSignature = async () => {
        try {
            setLoading(true)

            const result = await nfSignatureModel.get();

            setNfHeaders({
                'Content-Type': 'application/json',
                Authorization: `NODEFLUX-HMAC-SHA256 Credential=${env.NF_ACCESS_KEY}/${result.headers["x-nodeflux-timestamp"].substring(0, 8)}/nodeflux.api.v1beta1.ImageAnalytic/StreamImageAnalytic, SignedHeaders=x-nodeflux-timestamp, Signature=${result.token}`,
                'x-nodeflux-timestamp': result.headers["x-nodeflux-timestamp"]
            })
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    }

    useEffect(() => {
        if (history.action === 'POP') {
            history.goBack();
        } else {
            getNFSignature()
        }
    }, [])

    useEffect(() => {
        if (Object.keys(nfHeaders).length > 0) {
            getFaceRecognition()
        }
    }, [nfHeaders])

    return (
        <Container fluid>
            <Row>
                <Col style={Style.header}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Hasil Pengenalan Wajah
                </Col>
            </Row>

            <Row>
                <Col style={{fontWeight: '600', marginTop: 15}}>
                    Foto Input
                </Col>
            </Row>

            <Row>
                <Col style={{boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px', margin: 15, borderRadius: 5}}>
                    <img src={`data:image/jpeg;base64,${base64}`}
                         style={{width: '100%', height: 200, objectFit: 'contain'}}/>
                </Col>
            </Row>

            <Row>
                <Col style={{height: 1, backgroundColor: '#e0e0e0', marginTop: 15, marginBottom: 15}}/>
            </Row>

            <Row>
                <Col style={{fontWeight: '600'}}>
                    Hasil Pencarian
                </Col>
            </Row>

            <Row style={{padding: 15}}>
                {isLoading ?
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 15
                    }}>
                        <Spinner animation={'border'} variant={'secondary'}/>
                        <div style={{color: 'grey', marginTop: 10}}>
                            Memuat Hasil
                        </div>
                    </Col> :
                    candidates.length === 0 ?
                        <Col style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 15
                        }}>
                            <BsFileEarmarkExcel size={30} color={'grey'}/>

                            <div style={{color: 'grey', marginTop: 10}}>
                                Tidak Ada Data
                            </div>
                        </Col> :
                        candidates.map(candidate => {
                            return (
                                <Col xs={12} sm={6}>
                                    <div
                                        onClick={async () => {
                                            const result = await enrollmentModel.getByNIK(candidate.face_id);

                                            setLoading(false)

                                            console.log('result', result)

                                            history.push('person-detail', {data: result})
                                        }}
                                        style={{
                                            boxShadow: 'rgb(136, 136, 136, .3) 0px 0px 3px 1px',
                                            borderRadius: 5,
                                            position: 'relative'
                                        }}>
                                        <img src={candidate.additional_info.enrolled_face}
                                             style={{
                                                 width: '100%',
                                                 objectFit: 'cover',
                                                 borderTopLeftRadius: 5,
                                                 borderTopRightRadius: 5
                                             }}/>

                                        <div style={{
                                            position: 'absolute',
                                            top: 6,
                                            right: 6,
                                            backgroundColor: '#00000080',
                                            color: 'white',
                                            borderRadius: 5,
                                            paddingLeft: 4,
                                            paddingRight: 4,
                                            fontSize: '.9em',
                                            fontWeight: '600'
                                        }}>
                                            {candidate.confidence === 1 ? '99%' : Math.floor(candidate.confidence * 100) + '%'}
                                        </div>

                                        <div style={{padding: 10, fontSize: '.9em', fontWeight: '600'}}>
                                            {candidate.additional_info.full_name}
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                }
            </Row>
        </Container>
    )
}
