import apiConfig from "../util/apiConfig";
import ApiRequest from "../util/ApiRequest";
import env from "../util/env";

export default class AnalyticUsage {
    create = async (analyticType) => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/analytic_usage`, "POST", {
            analytic_type: analyticType
        });
    }
}
