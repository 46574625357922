import apiConfig from "../util/apiConfig";
import ApiRequest from "../util/ApiRequest";
import env from "../util/env";

export default class User {
    login = async (body) => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/user/login`, "POST", body);
    }

    tokenLogin = async () => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/user/tokenLogin`, "POST");
    }
}
