import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Style from "../Style";
import env from "../util/env";
import {Button} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {MdOutlineArrowBack} from "react-icons/md";
import {useHistory} from "react-router-dom";
import {Camera} from "react-camera-pro";
import {IoIosReverseCamera, IoMdImages, IoMdTrash} from "react-icons/io";
import {BiChevronRight} from "react-icons/bi";
import * as Swal from "sweetalert2";
import NFSignature from "../models/NFSignature";
import FaceLiveness from "../models/FaceLiveness";
import AnalyticUsage from "../models/AnalyticUsage";
import {AiOutlineRotateRight, CgCrop} from "react-icons/all";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Spinner from "react-bootstrap/Spinner";

export default function CameraPage(props) {
    const cropperRef = useRef(null);

    const setting = JSON.parse(localStorage.getItem('setting'));
    const {type} = props.location.state
    const isLivenessOn = (type === 'liveness') || (type === 'enrollment' && setting.enrollment.liveness) || (type === '1:N' && setting.faceRecognition.liveness) || (type === '1:1' && setting.verificationWithNIK.liveness) || (type === 'face-match' && setting.verificationWithKTP.liveness)
    const [savedBase64, setSavedBase64] = useState([])
    const nfSignatureModel = new NFSignature();

    const webcamRef = React.useRef(null);
    const [rawBase64, setRawBase64] = useState(null);
    const [base64, setBase64] = useState(null);

    const [cameraType, setCameraType] = useState('user');
    const history = useHistory();
    const faceLivenessModel = new FaceLiveness();
    const [nfHeaders, setNfHeaders] = useState({})
    const analyticUsageModel = new AnalyticUsage();
    const inputFileRef = useRef(null);
    const [isLivenessLoading, setLivenessLoading] = useState(false)
    const [numberOfCameras, setNumberOfCameras] = useState(0);

    useEffect(() => {
        if(type === 'ocr' || type === 'ocr-nik-search' || type === 'ocr-enrollment' || (type === '1:1' && props.location.state.base64) || (type === 'face-match' && savedBase64.length > 0)) {

        } else if(numberOfCameras === 2) {
            webcamRef.current.switchCamera()
        }
    }, [numberOfCameras, savedBase64])

    const handleCropChange = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;

        const croppedImgData = cropper
            .getCroppedCanvas()
            .toDataURL();

        setBase64(croppedImgData)
    }

    const rotateImage = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        cropper.rotate(90)

        handleCropChange()
    };

    const getNFSignature = async () => {
        try {
            const result = await nfSignatureModel.get();

            setNfHeaders({
                'Content-Type': 'application/json',
                Authorization: `NODEFLUX-HMAC-SHA256 Credential=${env.NF_ACCESS_KEY}/${result.headers["x-nodeflux-timestamp"].substring(0, 8)}/nodeflux.api.v1beta1.ImageAnalytic/StreamImageAnalytic, SignedHeaders=x-nodeflux-timestamp, Signature=${result.token}`,
                'x-nodeflux-timestamp': result.headers["x-nodeflux-timestamp"]
            })
        } catch (e) {
            console.log(e)
        }
    }

    const capture = React.useCallback(
        async () => {
            const imageSrc = webcamRef.current.takePhoto();

            if (isLivenessOn) {
                setLivenessLoading(true)

                try {
                    const livenessResult = await faceLivenessModel.get(imageSrc, nfHeaders)

                    console.log('livenessResult', livenessResult)

                    await analyticUsageModel.create('VISIONAIRE-CLOUD-SNAP-LV-FC Face Liveness (Cloud)')

                    if (livenessResult.message === "the face is most likely occluded") {
                        Swal.fire(
                            "Face Liveness Gagal!",
                            `Terdapat atribut pada wajah. Contoh atribut: masker, kacamata dan rambut pada dahi.`,
                            'error'
                        )
                    } else if (livenessResult.result[0].face_liveness.live) {
                        if (type === 'liveness') {
                            Swal.fire(
                                "Face Liveness Berhasil!",
                                ``,
                                'success'
                            )
                        } else {
                            setRawBase64(imageSrc)
                            setBase64(imageSrc)
                        }
                    } else {
                        Swal.fire(
                            "Face Liveness Gagal!",
                            `Foto tidak memenuhi kriteria liveness.`,
                            'error'
                        )
                    }
                } catch (e) {
                    console.log(e)
                }

                setLivenessLoading(false)
            } else {
                setRawBase64(imageSrc)
                setBase64(imageSrc)
                // if (type === 'face-match' && savedBase64.length === 0) {
                //     setBase64(env.TEST2_BASE64)
                // } else {
                //     setBase64(env.TEST_BASE64)
                // }
            }
        },
        [webcamRef, nfHeaders, savedBase64]
    );

    useEffect(() => {
        if (props.history.action === "POP" && props.location.state.base64) {
            history.goBack()
        } else if (isLivenessOn) {
            getNFSignature()
        }
    }, [])

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return canvas.toDataURL("image/jpeg")
    }

    return (
        <Container fluid style={{
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 0,
            paddingRight: 0,
            overflow: 'hidden'
        }}>
            <input type={'file'} ref={inputFileRef}
                   accept="image/jpeg"
                   style={{display: 'none'}}
                   onChange={(e) => {
                       var reader = new FileReader();
                       reader.readAsDataURL(e.target.files[0]);
                       reader.onload = function () {
                           setBase64(reader.result)
                           setRawBase64(reader.result)
                           console.log(reader.result);
                       };
                       reader.onerror = function (error) {
                           console.log('Error: ', error);
                       };
                   }}/>
            <Row>
                <Col style={{
                    ...Style.header,
                    paddingLeft: 25,
                    paddingRight: 25
                }}>
                    <MdOutlineArrowBack size={20} style={{marginRight: 15}} onClick={() => history.goBack()}/>
                    Ambil
                    Foto {type === 'ocr' || type === 'ocr-nik-search' || type === 'ocr-enrollment' || (type === '1:1' && props.location.state.base64) || (type === 'face-match' && savedBase64.length > 0) ? 'KTP' : 'Wajah'}
                </Col>
            </Row>


            {base64 ?
                <div style={{position: 'relative', backgroundColor: 'black'}}>
                    <Cropper
                        src={rawBase64}
                        style={{height: 'calc(100vh - 139px)', width: "100%"}}
                        ref={cropperRef}
                        cropend={handleCropChange}
                    />

                    {/*<ReactCrop src={base64}*/}
                    {/*           crop={crop}*/}
                    {/*           disabled={!isCropping}*/}
                    {/*           onImageLoaded={onImageLoaded}*/}
                    {/*           imageStyle={{*/}
                    {/*               height: 'calc(100vh - 145px)',*/}
                    {/*               width: '100vw',*/}
                    {/*               objectFit: 'cover'*/}
                    {/*           }}*/}
                    {/*           onChange={newCrop => {*/}
                    {/*               if (isCropping) {*/}
                    {/*                   setCrop(newCrop)*/}
                    {/*               }*/}
                    {/*           }}/>*/}

                    {/*<div style={{*/}
                    {/*    height: 'calc(100vh - 139px)',*/}
                    {/*    display: 'flex',*/}
                    {/*    alignItems: 'center'*/}
                    {/*}}>*/}
                    {/*    <img src={base64} style={{objectFit: 'cover', width: '100%'}}/>*/}
                    {/*</div>*/}
                    <div
                        onClick={() => setBase64(null)}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 25,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#000000B3',
                            position: 'absolute',
                            top: 15,
                            left: 15
                        }}>
                        <IoMdTrash color={'white'} size={20}/>
                    </div>

                    <div
                        onClick={rotateImage}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 25,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#000000B3',
                            position: 'absolute',
                            top: 15,
                            left: 65
                        }}>
                        <AiOutlineRotateRight color={'white'} size={20}/>
                    </div>

                    <div
                        onClick={() => {
                            const imageElement = cropperRef?.current;
                            const cropper = imageElement?.cropper;
                            // console.log();

                            const canvas = document.createElement('canvas');
                            var ctx = canvas.getContext("2d");

                            var image = new Image();
                            image.onload = function () {
                                canvas.width = image.width;
                                canvas.height = image.height;

                                ctx.drawImage(image, 0, 0);

                                const base64Temp = canvas.toDataURL("image/jpeg");

                                let pureBase64 = base64Temp.split(',')[1];

                                if (type === 'face-match') {
                                    const savedBase64Temp = [...savedBase64];

                                    if (savedBase64Temp.length === 0) {
                                        savedBase64Temp.push(pureBase64)

                                        setBase64(null)
                                        setSavedBase64(savedBase64Temp)
                                    } else {
                                        history.push('face-match', {
                                            base64: savedBase64Temp[0],
                                            ktpBase64: pureBase64
                                        })
                                    }
                                } else {
                                    history.push(type === 'face-demography' ? 'face-demography' : type === 'ocr-nik-search' ? 'search-nik' : type === '1:N' ? 'face-recognition' : type === '1:1' ? 'nik-validation' : type === 'enrollment' || type === 'ocr-enrollment' ? 'enrollment' : 'ocr-result', type === 'ocr-enrollment' || (type === '1:1' && props.location.state.base64) ? {
                                        ...props.location.state,
                                        ktpBase64: pureBase64
                                    } : {base64: pureBase64})
                                }
                            };

                            image.src = base64
                        }}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 25,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#000000B3',
                            position: 'absolute',
                            top: 15,
                            right: 15
                        }}>
                        <BiChevronRight color={'white'} size={20}/>
                    </div>
                </div> :
                <>
                        <Camera
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            facingMode={'environment'}
                            forceScreenshotSourceSize={true}
                            aspectRatio={window.innerWidth / window.innerHeight + (76 / window.innerHeight)}
                            numberOfCamerasCallback={setNumberOfCameras}
                        />
                    <div
                        onClick={() => {
                            inputFileRef.current.click()
                        }}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 25,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#000000B3',
                            position: 'absolute',
                            top: 70,
                            right: 60
                        }}>
                        <IoMdImages color={'white'} size={20}/>
                    </div>

                    <div
                        onClick={() => {
                            webcamRef.current.switchCamera();
                            // setCameraType(cameraType === 'user' ? 'environment' : 'user')
                        }}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 25,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#000000B3',
                            position: 'absolute',
                            top: 70,
                            right: 15
                        }}>
                        <IoIosReverseCamera color={'white'} size={20}/>
                    </div>
                </>
            }

            {Object.keys(nfHeaders).length > 0 || !isLivenessOn ?
                <div style={{
                    backgroundColor: 'black',
                    paddingTop: 15,
                    paddingBottom: 15,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div
                        onClick={() => {
                            if (!base64 && !isLivenessLoading) {
                                capture()
                            }
                        }}
                        style={{
                            border: '3px solid #dedede',
                            borderRadius: 30,
                            width: 55,
                            height: 55,
                            backgroundColor: 'white',
                            opacity: isLivenessLoading ? .3 : base64 ? 0 : 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        {isLivenessLoading ? <Spinner animation={'grow'}/> : null}
                    </div>
                </div>
                : null}
        </Container>
    )
}
