import env from "../util/env";
import apiConfig from "../util/apiConfig";
import ApiRequest from "../util/ApiRequest";

export default class FaceMatch {
    get = async (base64_1, base64_2, headers) => {
        return await ApiRequest.set(apiConfig.komune_base_url + `/v1/nf-cloud/face-match`, "POST", {
            images: [base64_1, base64_2],
            additional_params: {
                "similarity_threshold": 0.85
            }
        }, headers);
    }
}
